import * as Yup from 'yup';

import { IConsent } from '../models/consent';
import { IProposalFormValues } from '../models/proposal-form.model';
import { IProposal } from '../models/proposal.model';
import { useT } from '../hooks/use-translation';
import { getConsentsSchema } from './utils/get-consents-schema';
import { getConsentsInitialValues } from './utils/get-consents-initial-values';

export const emptyService: IProposalFormValues['proposals'][0]['services'][0] = {
    id: '',
    quantity: 0,
    options: [],
};

export function getProposalFormValues(
    consents: IConsent[],
    proposals: IProposal[]
): IProposalFormValues {
    return {
        client: {
            projectName: '',
            email: '',
            phone: '',
            comment: '',
        },
        proposals: proposals.map((proposal) => {
            const bundle = proposal.bundles[0];
            return {
                id: proposal.proposalId,
                bundleId: bundle?.bundleId || '',
                services: proposal.services.map((service) => {
                    const bundleService = bundle?.services.find(
                        (bundleService) => bundleService.serviceId === service.serviceId
                    );
                    if (bundleService) {
                        return {
                            id: [service.serviceId.toString()],
                            quantity: bundleService.quantity,
                            options: bundleService.options,
                        };
                    }
                    return emptyService;
                }),
            };
        }),
        ...getConsentsInitialValues(consents),
    };
}

export function getProposalFormSchema(t: ReturnType<typeof useT>['t'], consents: IConsent[]) {
    return Yup.object().shape({
        client: Yup.object({
            projectName: Yup.string().required(t('form.error.required')),
            email: Yup.string().email(t('form.error.email')).required(t('form.error.required')),
        }),
        ...getConsentsSchema(t, consents),
        consentAll: Yup.boolean(),
    });
}
