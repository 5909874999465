import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { IPage } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    content,
    logo,
    header,
    title,
    headerDescription,
    moreBox,
    moreTitle,
    moreText,
    moreLinks,
    moreLink,
    successBox,
    successTitleBox,
} from './proposal.module.scss';
import Logo from '../assets/images/svg/alterpage-logo.svg';
import Arrow from '../assets/images/svg/arrow-up-right.svg';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IProposal } from '../models/proposal.model';
import { useT } from '../hooks/use-translation';
import { getNodes } from '../utils/get-nodes';

import MainLayout from '../layouts/main-layout';
import Section from '../components/hoc/section';
import Markdown from '../components/hoc/markdown';
import Text from '../components/atoms/text';
import ProposalForm, { IProposalFormProps } from '../components/molecules/proposal-form';
import Title from '../components/atoms/title';
import Button from '../components/atoms/button';

interface IFaqPageProps {
    readonly data: {
        allProposal: IQueryAllResult<IProposal>;
        page: Pick<IPage, 'pathname'> | null;
    };
}

const ProposalPage: React.FC<IFaqPageProps> = ({ data }) => {
    const { t } = useT();
    const { allProposal, page } = data;
    const proposals = getNodes(allProposal);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleStatusChange: IProposalFormProps['onStatusChange'] = (status) => {
        setIsSuccess(status === 'success');
    };

    return (
        <MainLayout showFloatingCircles={false}>
            <Section circleDisplay="none" theme="dark" contentBoxClassName={content}>
                <Logo className={logo} />
                {!isSuccess && (
                    <>
                        <div className={header}>
                            <Markdown className={title}>{t('proposal.title')}</Markdown>
                            <Text className={headerDescription}>{t('proposal.description')}</Text>
                        </div>
                        <ProposalForm proposals={proposals} onStatusChange={handleStatusChange} />
                    </>
                )}
                {isSuccess && (
                    <div className={successBox}>
                        <div className={successTitleBox}>
                            <Title weight="bold">{t('proposal.success.title')}</Title>
                            <Title weight="regular" Tag="p">
                                {t('proposal.success.subtitle')}
                            </Title>
                        </div>
                        <Button color="blue" kind="normalDark" as="link" to={page?.pathname || ''}>
                            {t('proposal.success.button')}
                        </Button>
                    </div>
                )}
                <div className={moreBox}>
                    <h2 className={moreTitle}>{t('proposal.more.title')}</h2>
                    <p className={moreText}>{t('proposal.more.text')}</p>
                    <div className={moreLinks}>
                        <Link className={moreLink} to="/">
                            <Arrow />
                            {t('proposal.more.link.server')}
                        </Link>
                        <Link className={moreLink} to="/">
                            <Arrow />
                            {t('proposal.more.link.programming')}
                        </Link>
                    </div>
                </div>
            </Section>
        </MainLayout>
    );
};

export const query = graphql`
    query ProposalPage($locale: String!) {
        allProposal(filter: { locale: { eq: $locale } }, sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...proposalFields
                }
            }
        }
        page(type: { eq: "portfolio" }, locale: { eq: $locale }) {
            pathname
        }
    }
`;

export default ProposalPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
