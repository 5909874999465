// extracted by mini-css-extract-plugin
export var content = "proposal-module--content--DPVfV";
export var header = "proposal-module--header--ZUU49";
export var headerDescription = "proposal-module--header-description--TWAdc";
export var logo = "proposal-module--logo--AeNQk";
export var moreBox = "proposal-module--more-box--CSpNL";
export var moreLink = "proposal-module--more-link--JxSMk";
export var moreLinks = "proposal-module--more-links--FEQ4S";
export var moreText = "proposal-module--more-text--r7PhM";
export var moreTitle = "proposal-module--more-title--VzGiy";
export var successBox = "proposal-module--success-box--uKpjR";
export var successTitleBox = "proposal-module--success-title-box--Dctv4";
export var title = "proposal-module--title--1iPkD";