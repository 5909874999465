import React, { useEffect, useRef } from 'react';
import { useField } from 'formik';

import {
    container,
    serviceRowHeader,
    serviceDescription,
    serviceName,
    counter,
    optionInput,
} from './proposal-service-input.module.scss';
import { IProposalService } from '../../models/proposal.model';
import { IProposalFormValues } from '../../models/proposal-form.model';
import { emptyService } from '../../formik/proposal.form';

import Checkbox from '../atoms/checkbox';
import InputCounter from '../atoms/input-counter';
import Radio from '../atoms/radio';

interface IProposalServiceInputProps {
    className?: string;
    name: string;
    service: IProposalService;
}

const ProposalServiceInput: React.FC<IProposalServiceInputProps> = ({
    className = '',
    name,
    service,
}) => {
    const [, meta, helpers] = useField<IProposalFormValues['proposals'][0]['services'][0]>(name);
    const value = meta.value;
    const prevValueRef = useRef<IProposalFormValues['proposals'][0]['services'][0] | null>(null);

    useEffect(() => {
        const prevValue = prevValueRef.current;
        if (JSON.stringify(prevValue) === JSON.stringify(value)) return;
        if (!prevValue) {
            prevValueRef.current = value;
            return;
        }
        const isPrevSelected = Array.isArray(prevValue.id)
            ? prevValue.id.length > 0
            : !!prevValue.id;
        const isSelected = Array.isArray(value.id) ? value.id.length > 0 : !!value.id;
        const hasSelectedOptions = Array.isArray(value.options)
            ? value.options.length > 0
            : !!value.options;
        if (
            !isPrevSelected &&
            isSelected &&
            (!service.options.length || !hasSelectedOptions) &&
            !value.quantity
        ) {
            helpers.setValue({
                ...value,
                quantity: 1,
                options:
                    service.options.length > 0
                        ? service.optionsType === 'single'
                            ? service.options[0].value
                            : [service.options[0].value]
                        : [],
            });
        }
        if (isPrevSelected && !isSelected) {
            helpers.setValue({
                ...value,
                quantity: 0,
                options: [],
            });
        }
        if (isPrevSelected && isSelected && value.quantity === 0) {
            helpers.setValue(emptyService);
        }
        if (!isPrevSelected && !isSelected && value.quantity) {
            helpers.setValue({
                ...value,
                id: [service.serviceId.toString()],
                options:
                    service.options.length > 0
                        ? service.optionsType === 'single'
                            ? service.options[0].value
                            : [service.options[0].value]
                        : [],
            });
        }
        if (service.options.length > 0) {
            if (isPrevSelected && isSelected && !hasSelectedOptions) {
                helpers.setValue(emptyService);
            }
            if (!isPrevSelected && !isSelected && hasSelectedOptions) {
                helpers.setValue({
                    ...value,
                    id: [service.serviceId.toString()],
                    quantity: 1,
                });
            }
        }
        prevValueRef.current = value;
    }, [helpers, service, value]);

    return (
        <div className={`${container} ${className}`}>
            <div className={serviceRowHeader}>
                <Checkbox name={`${name}.id`} theme="dark" value={service.serviceId}>
                    <p className={serviceName}>{service.name}</p>
                </Checkbox>
                <InputCounter
                    className={counter}
                    name={`${name}.quantity`}
                    unit={service.unit}
                    min={0}
                    max={service.maxQuantity || undefined}
                />
            </div>
            <p className={serviceDescription}>{service.description}</p>
            {service.options?.length > 0 &&
                service.optionsType === 'single' &&
                service.options.map((option) => {
                    if (service.optionsType === 'single') {
                        return (
                            <Radio
                                className={optionInput}
                                key={`radio-option-${option.value}`}
                                name={`${name}.options`}
                                value={option.value}
                                canUncheck={true}
                            >
                                {option.label}
                            </Radio>
                        );
                    }
                    return (
                        <Checkbox
                            className={optionInput}
                            key={`checkbox-option-${option.value}`}
                            name={`${name}.options`}
                            value={option.value}
                        >
                            {option.label}
                        </Checkbox>
                    );
                })}
        </div>
    );
};

export default ProposalServiceInput;
