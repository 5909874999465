import React from 'react';
import { Field, FieldAttributes, FieldProps, useField } from 'formik';

import {
    wrapper,
    wrapperError,
    wrapperDisabled,
    wrapperChecked,
    input,
    text,
    errorText,
    radioMark,
} from './radio.module.scss';

import Markdown from '../hoc/markdown';
import FormikError from './formik-error';

export type IRadioProps = {
    imgUrl?: string;
    canUncheck?: boolean;
    showError?: boolean;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
} & FieldAttributes<Record<string | number, any>>;

const Radio: React.FC<IRadioProps> = ({
    className = '',
    name,
    id,
    disabled,
    children,
    imgUrl,
    canUncheck = false,
    showError = true,
    ...rest
}) => {
    const [, meta, helpers] = useField(name);
    const isChecked = meta.value && meta.value.toString() === rest.value.toString();
    const hasError = meta.error && meta.touched;

    const handleChange = () => {
        if (meta.value === rest.value) {
            helpers.setValue('', true);
        } else {
            helpers.setValue(rest.value, true);
        }
    };

    return (
        <label
            className={`${wrapper} ${className} ${hasError ? wrapperError : ''} ${
                disabled ? wrapperDisabled : ''
            } ${isChecked ? wrapperChecked : ''}`}
        >
            <Field id={id} name={name} {...rest}>
                {({ field }: FieldProps) => {
                    return (
                        <input
                            className={input}
                            type={canUncheck ? 'checkbox' : 'radio'}
                            disabled={disabled}
                            {...field}
                            value={rest.value}
                            checked={isChecked}
                            onChange={canUncheck ? handleChange : field.onChange}
                        />
                    );
                }}
            </Field>
            <span className={radioMark} />
            {typeof children === 'string' ? (
                <Markdown
                    className={text}
                    // The Markdown component has a function that transforms the 'p' element,
                    // for some unknown reason this transformation makes it necessary
                    // to double-click on the label to select the checkbox.
                    // The following line disables the transformation of the 'p' element.
                    components={{ p: 'p' }}
                >
                    {children}
                </Markdown>
            ) : (
                <span className={text}>{children}</span>
            )}
            {showError && <FormikError className={errorText} name={name} />}
        </label>
    );
};

export default Radio;
