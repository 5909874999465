import React from 'react';
import { useField } from 'formik';

import {
    container,
    valueBox,
    valueNumber,
    button,
    plus,
    minus,
    faded,
} from './input-counter.module.scss';

interface ICounterProps {
    className?: string;
    name: string;
    unit?: string;
    min?: number;
    max?: number;
}

const InputCounter: React.FC<ICounterProps> = ({ className = '', unit, name, min, max }) => {
    const [, meta, helpers] = useField(name);
    const value = meta.value || 0;

    const handleChange = (operation: 'add' | 'subtract') => {
        return () => {
            let newValue = value;
            if (operation === 'add') {
                newValue = value + 1;
            }
            if (operation === 'subtract') {
                newValue = value - 1;
            }
            if ((max !== undefined && newValue > max) || (min !== undefined && newValue < min))
                return;
            helpers.setValue(newValue);
        };
    };

    return (
        <div className={`${container} ${className}`}>
            <button
                type="button"
                className={`${button} ${plus}`}
                onClick={handleChange('add')}
                disabled={(max === 0 || !!max) && max === value}
            />
            <p className={`${valueBox} ${value === min ? faded : ''}`}>
                <span className={valueNumber}>{value}</span>
                {unit && <span>{unit}</span>}
            </p>
            <button
                type="button"
                className={`${button} ${minus}`}
                onClick={handleChange('subtract')}
                disabled={(min === 0 || !!min) && min === value}
            />
        </div>
    );
};

export default InputCounter;
