// extracted by mini-css-extract-plugin
export var bundleButton = "proposal-form-module--bundle-button--vflen";
export var bundlesBox = "proposal-form-module--bundles-box--CowEV";
export var comment = "proposal-form-module--comment--G1F4y";
export var consentFields = "proposal-form-module--consent-fields--cOyvv";
export var container = "proposal-form-module--container--eJ72E";
export var dataFields = "proposal-form-module--data-fields--Lcu5b";
export var dataTitle = "proposal-form-module--data-title--qlJOk";
export var loading = "proposal-form-module--loading--VPDdw";
export var proposalBox = "proposal-form-module--proposal-box--SWVl6";
export var proposalContent = "proposal-form-module--proposal-content--KdKBV";
export var proposalTitle = "proposal-form-module--proposal-title--lX0qe";
export var servicesHeader = "proposal-form-module--services-header--+bElp";
export var servicesHeaderLabel = "proposal-form-module--services-header-label--QDoWp";
export var servicesList = "proposal-form-module--services-list--cr-93";